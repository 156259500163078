.close-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    width: fit-content;
    cursor: pointer;
}
