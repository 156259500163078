.button-container {
	background: linear-gradient(82.77deg, #287fc5 -2.25%, #321787 88.27%);
	padding: 1rem 2rem;
	border-radius: 0.875rem;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	flex-direction: row-reverse;
	&.red{
		background:red;
	}
	&.disabled {
		opacity: 0.5;
		cursor: unset;
	}
	span {
		color: #fff;
		font-size: 0.75rem;
	}
}
@media (min-width: 1024px) {
	.button-container {
		span {
			font-size: 0.85rem;
		}
	}
}
