.banner-swiper {
	width: 100%;
	height: 35rem;
	background-color: $sub-grey-color;
	border-radius: $border-radius;
	overflow: hidden;
	position: relative;
	padding: 1rem;
	.banner-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 1rem;
		z-index: 0;
		transition: all 1s;
		overflow: hidden;
		border-radius: $border-radius;
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
		img {
			width: 100%;
			height: 100%;
			transition: all 0.75s;
			border-radius: $border-radius;
			object-fit: cover;
		}
		&.active {
			clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
		}
		&.prev {
			clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
		}
		.overlay {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.4);
			z-index: 2;
		}
	}
	.swiper-pagination-bullets {
		position: absolute;
		width: 100%;
		display: flex;
		gap: 0.25rem;
		align-items: center;
		justify-content: center;
		left: 0;
		z-index: 3;
		bottom: 4rem;
		.swiper-pagination-bullet {
			padding: 1rem 0;
			border-radius: 0;
			width: auto;
			height: auto;
			background-color: transparent;
			// cursor: pointer;
			opacity: 1;
			div {
				width: 4rem;
				height: 3px;
				border-radius: 0;
				transition: 0.25s all;
				background-color: red;
				background: rgba($color: #fff, $alpha: 0.4);
				position: relative;
				&.sliderDir {
					&::after {
						left: unset;
						right: 0;
					}
				}
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					transition: 0;
					z-index: 1;
					background: #fff;
				}
			}
			&.active {
				div {
					&::after {
						transition: 4s ease-in;
						width: 100%;
					}
					&.firstLoad {
						&::after {
							animation: drawLine 4s ease-in;
						}
					}
				}
			}
		}
	}
	.overlay {
		position: absolute;
		width: calc(100% - 1rem * 2);
		height: calc(100% - 1rem * 2);
		z-index: 1;
		border-radius: $border-radius;
		background-color: rgba($color: #000000, $alpha: 0.5);
	}
	.banner-data {
		position: absolute;
		z-index: 2;
		left: 2rem;
		top: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fff;
		p {
			width: 90%;
			margin: 1.5rem 0;
		}
		h1 {
			font-size: 2.75rem;
		}
		.location {
			display: flex;
			gap: 0.25rem;
			align-items: center;
			margin-bottom: 1rem;
		}
		.see-all {
			cursor: pointer;
			width: fit-content;
		}
	}
	.scroll-down {
		display: none;
		position: absolute;
		z-index: 3;
		color: #fff;
		right: 2rem;
		bottom: 2rem;
		cursor: pointer;
		align-items: center;
		gap: 0.5rem;
		.scroll-line {
			width: 2px;
			height: 3rem;
			background-color: rgba($color: #fff, $alpha: 0.3);
			position: relative;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				width: 100%;
				background-color: #fff;
				animation: drawLine2 1s infinite alternate;
			}
		}
	}
}
@media (min-width: 1024px) {
	.banner-swiper {
		height: 95vh;
		padding: 1.667rem;
		.banner-img {
			padding: 1.667rem;
		}
		.banner-data {
			padding: 1.667rem;
			left: 4.667rem;
			margin-top: 8rem;
			width: 50%;
			p {
				width: 50%;
			}
			h1 {
				font-size: 3.25rem;
			}
		}
		.overlay {
			width: calc(100% - 1.667rem * 2);
			height: calc(100% - 1.667rem * 2);
		}
		.swiper-pagination-bullets {
			gap: 1rem;
			bottom: 3rem;
			.swiper-pagination-bullet {
				div {
					width: 10rem;
				}
			}
		}
		.scroll-down {
			right: 3.667rem;
			bottom: 4rem;
			display: flex;
		}
	}
}
@keyframes drawLine {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
@keyframes drawLine2 {
	0% {
		height: 0%;
	}
	100% {
		height: 100%;
	}
}
