@import "./styles/variables.scss";
@import "./styles/common/Button.scss";
@import "./styles/common/CloseButton.scss";
@import "./styles/common/ImageModal.scss";
@import "./styles/components/WidgetDetails.scss";
@import "./styles/components/WidgetTabs.scss";
@import "./styles/components/WidgetCalender.scss";
@import "./styles/components/Gallery.scss";
@import "./styles/components/Confirming.scss";
@import "./styles/components/Info.scss";
@import "./styles/components/WidgetSummary.scss";
@import "./styles/components/Banner.scss";
@import "./styles/components/Extra.scss";

@font-face {
	font-family: "public-font";
	src: url("../public/fonts/PlusJakartaSans-Light.ttf");
	font-display: swap;
}

@font-face {
	font-family: "public-font-medium";
	src: url("../public/fonts/PlusJakartaSans-Light.ttf");
	font-display: swap;
}

@font-face {
	font-family: "public-font-bold";
	src: url("../public/fonts/PlusJakartaSans-Medium.ttf");
	font-display: swap;
}

.cap {
	text-transform: uppercase !important;
}

.center {
	text-align: center;
}

.light {
	font-family: "public-font";
	font-weight: 500;
}

.medium {
	font-family: "public-font-medium";
	font-weight: 600;
}

.bold {
	font-family: "public-font-bold";
	font-weight: 700;
}

html {
	font-size: 16px;
	direction: ltr;
}

html,
body {
	overscroll-behavior-y: none;
	-webkit-overflow-scrolling: touch;
	font-family: "public-font" !important;
	background-color: #d4d4d4;
}

html,
body {
	padding: 0;
	margin: 0;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
}

body.no-scroll {
	position: fixed;
	overflow: hidden;
	height: 100%;
}

* {
	box-sizing: border-box;
}

button {
	border: none;
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	line-height: 1.1;
}

p,
a,
ul,
ol {
	margin: 0;
	padding: 0;
	// font-size: 1rem;
}

ul {
	list-style: none;
}

h1 {
	font-size: 1.5rem;
}

h2 {
	font-size: 1.15rem;
}

h4 {
	font-size: 0.975rem;
}

p {
	font-size: 0.9rem;
	line-height: 1.5;
}

span,
li {
	font-size: 0.85rem;
	font-family: "public-font";
}

.widget {
	position: relative;
	// padding: $padding-mob;
	padding: 2.5rem 1rem 1rem;
	;
}

.modal-container {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	z-index: 30;

	&.cancel {
		background-color: rgba(0, 0, 0, .2);

		.cancel-wrapper {
			background-color: #fff;
			padding: 2.5rem 2rem 2rem;
			border-radius: 8px;
			position: relative;
			min-width: 30%;

			.close-button-container {
				position: absolute;
				right: 1rem;
				top: 1rem;
				padding: 0;
				transform: scale(.75);
			}

			h4 {
				margin-bottom: 2rem;
			}

			.button-container {
				margin-top: 1rem;
			}

			input {
				margin-top: 1rem;
				font-family: "public-font" !important;
				font-weight: 600;
				padding: 1.25rem 1rem;
				width: 100%;
				border-radius: $border-radius;
				border: 1px solid $border-grey-color;

				&:focus-visible,
				&:focus {
					outline: none;
					border: 1px solid $border-grey-color;
				}

				&::placeholder {
					color: $grey-color;
					font-weight: 500;
					font-size: 0.85rem;
				}
			}
		}
	}
}

ul,
ol {
	padding-inline-start: 1rem;
}

.Toastify__toast-container {
	top: 5.5rem !important;
	right: 2rem !important;

	// right: unset !important;
	// width: calc(100% - $padding-inline-mobile) !important;
	// left: $padding-inline-mobile / 2 !important;
	.Toastify__toast {
		min-height: unset !important;
		height: 5.5rem !important;
		padding: 0 !important;
		margin: 0 !important;
		margin-bottom: 1rem !important;
		position: relative;
		font-size: 1rem;
		color: #000;
		transition: 0.25s all;
		font-weight: 500;

		// &.Toastify__toast--error {
		// 	background-color: #e80710;
		// }
		// &.Toastify__toast--success {
		// 	background-color: #0559f7;
		// 	color: white;
		// }
		.Toastify__progress-bar {
			height: 3px !important;
			// opacity: 0;
		}

		// .Toastify__toast-icon {
		// 	// display: none;
		// }
		.Toastify__toast-body {
			margin: 0;
			width: 100%;
			padding: 0;
			padding-inline: 1rem;
		}

		button {
			position: absolute;
			right: 1rem;
			top: 0.5rem;
			opacity: 1;

			// display: none;
			svg {
				path {
					fill: #000;
				}
			}
		}
	}
}

.loader-page {
	min-height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&.success {
		min-height: 100vh;
	}

	.submit-loading-container {
		box-sizing: border-box;
		display: block;
		margin: auto;
		height: 4rem;
		width: 4rem;
		border: 2px solid #ede7f6;
		border-top: 2px solid #211d33;
		border-radius: 50%;
		animation: lds-dual-ring 2s linear infinite;
	}

	&.login {
		min-height: unset;

		.submit-loading-container {
			width: 1.5rem;
			height: 1.5rem;
			border: 2px solid #ede7f6;
			border-top: 2px solid #211d33;
		}
	}

	&.edit {
		.submit-loading-container {
			width: 1.75rem;
			height: 1.75rem;
		}
	}

	&.sign-up-loading {
		.submit-loading-container {
			width: 3.5rem;
			height: 3.5rem;
		}
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.cancel-modal {
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 1024px) {
	html {
		font-size: 12px;
		overflow-x: hidden;
	}

	h1 {
		font-size: 2.25rem;
	}

	h2 {
		font-size: 1.3rem;
	}

	h4 {
		font-size: 1.05rem;
	}

	p {
		font-size: 1rem;
	}

	span,
	li {
		font-size: 0.9rem;
	}

	.widget {
		padding: 3rem;
	}

}

@media (min-width: 1279px) {
	html {
		font-size: 14px;
	}
}

@media (min-width: 1440px) {
	html {
		font-size: 16px;
	}
}

@media (min-width: 1680px) {
	html {
		font-size: 18px;
	}
}

@media (min-width: 1900px) {
	html {
		//from 20px to 18px
		font-size: 18px;
	}
}