.modal-container {
	.img-container {
		width: 95%;
		height: 60%;
		border-radius: 6px;
		overflow: hidden;
		padding: 0.5rem;
		background-color: #fff;
		position: relative;
		.swiper {
			width: 100%;
			height: 100%;
			border-radius: 6px;
			overflow: hidden;
		}
		.img-wrapper {
			width: 100%;
			height: 100%;
			border-radius: 6px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				border-radius: 6px;
				overflow: hidden;
				object-fit: cover;
			}
		}
		.arrows-container {
			width: 100%;
			position: absolute;
			top: 50%;
			padding-inline: 2rem;
			left: 0;
			z-index: 1;
			display: flex;
			justify-content: space-between;
			z-index: 2;
			.swiper-button {
				background-color: #fff;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				&.swiper-button-disabled {
					opacity: 0.7;
					svg {
						opacity: 0.5;
					}
				}
			}
		}
		.close-button-container {
			position: absolute;
			right: 1rem;
			top: 1rem;
			transform: scale(0.75);
			z-index: 1;
		}
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 6px;
			height: calc(100% - 0.5rem * 2);
			width: calc(100% - 0.5rem * 2);
			background: rgba(0, 0, 0, 0.5);
			z-index: 1;
			margin: 0.5rem;
		}
	}
}
@media (min-width: 1024px) {
	.modal-container {
		.img-container {
			width: 80%;
			height: 80%;
		}
	}
}
