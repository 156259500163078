//CSS Variables
$grey-color: #d4d4d4;
$border-grey-color: #ddd;
$sub-grey-color: #f1f5f9;
$blue-color: #005cbb;
$red-color:#ff0000;
$padding-desk: 1.667rem;
$padding-mob: 1rem;
$padding-mob: 1rem;
$border-radius: 12px;
