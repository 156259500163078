.extra-container {
	margin-bottom: 1rem;
	background-color: #fff;
	border-radius: $border-radius;
	padding: 2rem 1rem;
	.cards-container {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 2rem;
		.extra-card {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			width: 100%;
			border: 1px solid $border-grey-color;
			border-radius: $border-radius;
			padding: 1.5rem;
			.img-container {
				height: 13rem;
				border-radius: $border-radius;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.data {
				h4 {
					margin-bottom: 0.75rem;
					font-weight: 700;
					font-size: 1.1rem;
					text-transform: capitalize;
				}
				.type {
					padding-inline-end: 1rem;
					user-select: none;
					margin-top: 1rem;
					width: fit-content;
					&.disabled {
						cursor: not-allowed;
						opacity: 0.6;
						width: fit-content;
					}
					.selection-group {
						display: flex;
						flex-wrap: wrap;
						gap: 1rem;
						margin-top: 1rem;
					}
					.name {
						user-select: none;
					}
					p {
						display: flex;
						flex-direction: column;
						user-select: none;
						span {
							opacity: 0.7;
							font-size: 0.75rem;
							user-select: none;
						}
					}
					&:last-child {
						padding-inline-end: unset;
					}
				}
				.counter {
					display: flex;
					border: 1px solid $border-grey-color;
					border-radius: $border-radius;
					overflow: hidden;
					width: fit-content;
					margin-top: 1rem;
					margin-bottom: 0.25rem;
					div {
						height: 3.25rem;
						font-weight: 700;
						font-size: 0.875rem;
						transition: 0.25s all;
						min-width: 3rem;
						display: flex;
						justify-content: center;
						align-items: center;
						&.sign {
							font-size: 1rem;
							background-color: $sub-grey-color;
							cursor: pointer;
							position: relative;
							&.disabled {
								cursor: unset;
								&:hover {
									color: #000;
									background-color: $sub-grey-color;
								}
							}
							&:hover {
								color: #fff;
								background-color: $blue-color;
							}
							span {
								user-select: none;
							}
						}
						input {
							border: unset;
							outline: unset;
							width: 100%;
							font-family: inherit !important;
							font-weight: 700;
							font-size: 0.875rem;
							width: 4rem;
							text-align: center;
							&.disabled {
								cursor: not-allowed;
							}
						}
					}
					input[type="number"]::-webkit-inner-spin-button,
					input[type="number"]::-webkit-outer-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					input[type="number"] {
						-moz-appearance: textfield; /* Firefox */
					}
				}
				.numbers-errors {
					position: absolute;
					bottom: -1rem;
					color: red;
					font-size: 0.85rem;
				}
				.note {
					color: $blue-color;
				}
				.price-group {
					display: flex;
					gap: 2rem;
					margin-top: 2rem;
					.button-container {
						background: #000;
						width: 11rem;
						height: 3rem;
						border-radius: 100px;
					}
					.price {
						span {
							font-size: 0.675rem;
						}
					}
				}
			}
		}
	}
	.button-group {
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;
	}
}
@media (min-width: 1024px) {
	.extra-container {
		padding: 2rem 2.5rem;
		h4 {
			font-size: 1.2rem;
		}
		.cards-container {
			.extra-card {
				padding: 2rem;
				padding-inline-end: 1rem;
				flex-direction: row;
				gap: unset;
				.data {
					width: 65%;
					padding-inline-start: 2rem;
					.type{
						padding-inline-end: 2.5rem;
					}
				}
				.img-container {
					min-height: 15rem;
					width: 35%;
				}
				.data {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.price-group {
						margin-top: 3rem;
					}
				}
			}
		}
	}
}
